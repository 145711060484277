import React, { useState, useEffect, useReducer } from "react";
import { parseISO, format, differenceInWeeks } from 'date-fns';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Placeholder from 'react-bootstrap/Placeholder';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
import Question from '../question';
import { startSession, updateSession, getSession } from '../../../_services/survey';
import { createGoal, updateGoal } from '../../../_services/goal';
import { getAllReflections } from '../../../_services/reflection';
import { useKeyPress } from '../../../_services/key-press';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStrategies } from '../../../_services/strategy';
import { handleStepCompletion, resetCurrentWorkflow } from '../../user-workflow/user-workflow-controller';
import { ReactComponent as SunIcon } from '../../../images/icons/sun.svg';


export default function Session(props: any) {

	let navigate = useNavigate();
	const keyFeaturesStep = 3;
	const wizardIdArray = [
		'SV_abLKXluEfwOmpIa', // personalized greetings
		'SV_08IehtWkMGvh28C', // student check-ins
		'SV_9nJWZO3l4gEEPrM', // community circles
		'SV_b3fgPuNCVMLCAzY', // classroom expectations
		'SV_eqBFokGanyhGyiy', // acknowledging positive behavior
		'SV_8kKR4CFK7ooEIU6', // corrective feedback
		'SV_3Ff1iiSingfNJPg', // teach prosocial skills
		'SV_037jGlu9wB8W4vA', // routines
		'SV_1Libs3Ka8XSd9iK', // effective questioning
		'SV_7Ut0ziCqNNPvebc' // student choice
	];
	const questionPairs = [
		// personalized greetings
		['QID47', 'QID48'],
		['QID39', 'QID46'],
		['QID45', 'QID49'],
		['QID43', 'QID50'],
		['QID41', 'QID51'],
		// student check-ins
		['QID5', 'QID6'],
		['QID8', 'QID9'],
		['QID11', 'QID12'],
		// community circles
		['QID14', 'QID15'],
		// classroom expectations
		['QID12', 'QID13'],
		['QID15', 'QID16'],
		['QID18', 'QID19'],
		['QID21', 'QID22'],
		['QID34', 'QID35'],
		['QID44', 'QID45'],
		// acknowledging positive behavior
		['QID24', 'QID25'],
		// corrective feedback
		['QID7', 'QID8'],
		['QID10', 'QID11'],
		['QID13', 'QID14'],
		['QID16', 'QID17'],
		['QID19', 'QID20'],
		['QID22', 'QID23'],
		// teach prosocial skills
		['QID6', 'QID7'],
		['QID9', 'QID10'],
	];

	const WIZARD_QUESTIONS = [
		{
			// personalized greetings
			survey_id: 'SV_abLKXluEfwOmpIa',
			question_pairs: [
				['QID47', 'QID48'],
				['QID39', 'QID46'],
				['QID45', 'QID49'],
				['QID43', 'QID50'],
				['QID41', 'QID51'],
			]
		},
		{
			// student check-ins
			survey_id: 'SV_08IehtWkMGvh28C',
			question_pairs: [
				['QID5', 'QID6'],
				['QID8', 'QID9'],
				['QID11', 'QID12'],
			]
		},
		{
			// community circles
			survey_id: 'SV_9nJWZO3l4gEEPrM',
			question_pairs: [
				['QID5', 'QID6'],
				['QID8', 'QID9'],
				['QID11', 'QID12'],
				['QID14', 'QID15'],
			]
		},
		{
			// classroom expectations
			survey_id: 'SV_b3fgPuNCVMLCAzY',
			question_pairs: [
				['QID12', 'QID13'],
				['QID15', 'QID16'],
				['QID18', 'QID19'],
				['QID21', 'QID22'],
				['QID34', 'QID35'],
				['QID44', 'QID45'],
			]
		},
		{
			// acknowledging positive behavior
			survey_id: 'SV_eqBFokGanyhGyiy',
			question_pairs: [
				['QID18', 'QID19'],
				['QID21', 'QID22'],
				['QID24', 'QID25'],
				['QID38', 'QID39'],
			]
		},
		{
			// corrective feedback
			survey_id: 'SV_8kKR4CFK7ooEIU6',
			question_pairs: [
				['QID7', 'QID8'],
				['QID10', 'QID11'],
				['QID13', 'QID14'],
				['QID16', 'QID17'],
				['QID19', 'QID20'],
				['QID22', 'QID23'],
			]
		},
		{
			// teach prosocial skills
			survey_id: 'SV_3Ff1iiSingfNJPg',
			question_pairs: [
				['QID6', 'QID7'],
				['QID9', 'QID10'],
				['QID12', 'QID13'],
				['QID15', 'QID16'],
			]
		},
		{
			// routines
			survey_id: 'SV_037jGlu9wB8W4vA',
			question_pairs: [
				['QID6', 'QID7'],
				['QID9', 'QID10'],
				['QID12', 'QID13'],
			]
		},
		{
			// effective questioning
			survey_id: 'SV_1Libs3Ka8XSd9iK',
			question_pairs: [
				['QID6', 'QID7'],
				['QID9', 'QID10'],
				['QID12', 'QID13'],
			]
		},
		{
			// student choice
			survey_id: 'SV_7Ut0ziCqNNPvebc',
			question_pairs: [
				['QID6', 'QID7'],
				['QID9', 'QID10'],
				['QID12', 'QID13'],
				['QID15', 'QID16'],
			]
		},
	];


	const [session, setSession] = useState<any>();
	const [done, setDone] = useState<any>(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [progressReady, setProgressReady] = useState(false);
	const [questionsReady, setQuestionsReady] = useState<boolean>(false);
	const [responses, setResponses] = useState<any>();
	const [validationErrors, setValidationErrors] = useState<any>();
	const [hasErrors, setHasErrors] = useState(false);
	const [btnText, setBtnText] = useState('Next');
	const [questions, setQuestions] = useState<any>();
	const [embeddedData, setEmbeddedData] = useState<any>();
	const [currentStep, setCurrentStep] = useState(1);
	const [goalStatement, setGoalStatement] = useState<string>();
	const [goalDescription, setGoalDescription] = useState<string>();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [modalType, setModalType] = useState<string>('');
	const [reflections, setReflections] = useState<any>([]);
	const [reflectionIds, setReflectionIds] = useState<any>([]);
	const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
	const [goalArchived, setGoalArchived] = useState<boolean>(false);
	const [goalComplete, setGoalComplete] = useState<boolean>(false);
	const [selfAssessmentError, setSelfAssessmentError] = useState<boolean>(false);

	useEffect(() => {
		initSurveySession();
		if (!isGoalReview() && !isGoalWizard() && !isSelfAssessment() && reflections.length === 0) {
			getAllReflectionIds();
		}
	}, []);

	useEffect(() => {
		if (dataLoaded) {
			if (!questionsReady) {
				getQuestions();
			}
			if (!progressReady) {
				calculateProgress();
			}
		}
	});

	useEffect(() => {
		//console.log('validationErrors', validationErrors);
		if (validationErrors) {
			getQuestions();
		}
	}, [validationErrors]);

	useEffect(() => {
		if (isGoalWizard()) {
			if (currentStep === 0 && !goalStatement && questionsReady) {
				getNewGoalStatement();
			}
		}
	}, [currentStep]);

	const getNewGoalStatement = () => {
		let q1Id = '';
		let q2Id = '';

		var q1Element: any;
		var q2Element: any;

		let statement = document.getElementById('goal-statement')!.textContent!.toString();
		let description = document.getElementById('goal-details')!.textContent!.toString();

		WIZARD_QUESTIONS.forEach(wizard => {
			if (wizard.survey_id === props.survey.id) {
				wizard.question_pairs.forEach(pair => {
					pair.forEach((item, index) => {
						if (index === 0) {
							let parent = document.getElementById('question_1')!;
							if (parent.querySelector('#' + item) !== null) {
								q1Id = item;
								q1Element = (document.getElementById(item) as HTMLInputElement)!;
							}
						} else {
							let parent = document.getElementById('question_2')!;
							if (parent.querySelector('#' + item) !== null) {
								q2Id = item;
								q2Element = (document.getElementById(item) as HTMLInputElement)!;
							}
						}
					});
				});
			}
		});

		q1Element.value = statement;
		q2Element.value = description;
		setGoalStatement(statement);
		setGoalDescription(description);
		setResponses({
			...responses, ...{
				[q1Id]: statement,
				[q2Id]: description
			}
		});
	}

	const initSurveySession = () => {
		//console.log('initSurveySession');
		// setRequestInProgress(true);
		// localStorage.removeItem('session_id');
		// startSurveySession();
		let session = localStorage.getItem('session_id');
		if (session === null || session === undefined) {
			startSurveySession();
		} else {
			getSurveySession(session);
		}
	}

	const getAllReflectionIds = () => {
		getAllReflections().then(resp => {
			let arr: any = [];
			Object.keys(resp.data.data).forEach(key => {
				arr.push(resp.data.data[key].survey_id);
			});
			setReflections(resp.data.data);
			setReflectionIds(arr);
		}).catch(err => {
			toast('Failed to load reflection IDs');
		});
	}

	const startSurveySession = () => {
		localStorage.removeItem('session_id');
		setDataLoaded(false);
		setProgressReady(false);
		setQuestionsReady(false);
		setGoalStatement('');
		setGoalDescription('');
		if (isGoalReview()) {
			setGoalStatement(props.goal.statement);
			setGoalDescription(props.goal.description);
		}
		startSession(props.survey.id).then(resp => {
			setSession(resp.data.data);
			//console.log('start sesh', resp.data.data);
			if (props.survey.id !== process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID) {
				localStorage.setItem('session_id', resp.data.data.sessionId);
			}
			setDataLoaded(true);
			setEmbeddedData({});
			setRequestInProgress(false);
		}).catch(err => {
			let resp = err.response.data.meta.httpStatus;
			setHasErrors(true);
			toast('Failed to start session');
			navigate('../dashboard', { replace: true });
			setRequestInProgress(false);
		});
	}

	const getSurveySession = (sessionId: any) => {
		setDataLoaded(false);
		getSession(sessionId, props.survey.id).then(resp => {
			//console.log('get sesh', resp.data.data);
			setSession(resp.data.data);
			if (props.survey.id !== process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID) {
				localStorage.setItem('session_id', resp.data.data.sessionId);
			}
			setDataLoaded(true);
			setRequestInProgress(false);
		}).catch(err => {
			setHasErrors(true);
			let resp = err.response.data.meta.httpStatus;
			if (err.response.status === 400) {
				startSurveySession();
			} else {
				setDataLoaded(false);
				toast('Failed to get survey data');
				navigate('../dashboard', { replace: true });
				setRequestInProgress(false);
			}
		});
	}

	const updateSurveySession = (type?: string) => {
		console.log('updateSurveySession');
		let request = {
			advance: true,
			responses: responses,
			embeddedData: embeddedData
		}

		if (type === 'blank') {
			request.responses = {};
		}

		// show special loading screen at end of self asessment
		if (isSelfAssessment() && currentStep === 10) {
			setDataLoaded(false);
		}

		setBtnText('Processing...');

		updateSession(session.sessionId, props.survey.id, request).then(resp => {
			if (resp.data.data.done) {
				localStorage.removeItem('session_id');
				props.progress(100);
				setDone(true);
				setDataLoaded(true);
				if (isGoalReview()) {
					logGoalReviewMetadata();
				}
			} else {
				setQuestionsReady(false);
				setProgressReady(false);
				setDone(false);
			}

			if (type === 'skip') {
				updateSurveySession('blank');
			} else {
				setSession(resp.data.data);
				resetPage();
			}
			if (isGoalReview()) {
				setShowModal(false);
			}
		}).catch(err => {
			setHasErrors(true);
			if (err.response.data && err.response.data.data) {
				let problems = err.response.data.data.problems;
				if (problems) {
					setValidationErrors(problems);
					toast.error('Please complete all questions.');
				} else {
					toast.error('Failed to save data. Please check fields and try again.');
				}
				setBtnText('Next');
			} else {
				if (isSelfAssessment()) {
					toast.error('Failed to generate strategy scores. Please contact technical support.');
					setSelfAssessmentError(true);
				} else {
					toast.error('Failed to save data. Please check fields and try again.');
					setBtnText('Next');
				}
			}


		});
	}

	const logGoalReviewMetadata = () => {
		let request = {
			statement: goalStatement || props.goal.statement,
			description: goalDescription || props.goal.description,
			review_count: props.goal.review_count + 1,
			reviewed_at: format(new Date(), 'yyyy-MM-dd'),
			is_archived: goalArchived,
			is_complete: goalComplete
		}
		updateGoal(props.goal.id, request).then((resp: any) => {
		}).catch((err: any) => {
			toast('Failed to load goal data');
		});
	}

	const calculateProgress = () => {
		let questionId, blockId, step;
		let total = props.survey.flow.length;
		questionId = session.questions[0].questionId;
		blockId = getBlockId(questionId);
		step = getFlowStep(blockId);
		setCurrentStep(step);
		if (step === 0) { // temp fix for dynamic form flows
			step = 5;
		}
		props.progress(Math.floor(step / total * 100));
		setProgressReady(true);
	}

	const getBlockId = (questionId: string) => {
		let resp = '';
		Object.keys(props.survey.blocks).forEach((key: string) => {
			props.survey.blocks[key].elements.forEach((item: any) => {
				if (item.questionId === questionId) {
					resp = key;
				}
			});
		});
		return resp;
	}

	const getFlowStep = (blockId: string) => {
		let resp = 0;
		Object.keys(props.survey.flow).forEach((key: any) => {
			if (props.survey.flow[key].id === blockId) {
				resp = parseInt(key) + 1;
			}
		});
		return resp;
	}

	const handleResponse = (data: any, choice: any, question: any, sequence: string) => {
		setQuestionsReady(false);
		if (isGoalWizard()) {
			handleGoalWizardResponse(data, choice, question, sequence);
		}
		setResponses({ ...responses, ...data });
	}

	const handleGoalWizardResponse = (data: any, choice: any, question: any, sequence: string) => {
		//console.log('handleGoalWizardResponse');
		if (currentStep === 0) {

			// console.log('response data', data);

			// console.log(WIZARD_QUESTIONS);

			let thisWizard = WIZARD_QUESTIONS.find(wizard => wizard.survey_id === props.survey.id);

			Object.keys(data).forEach((key: any) => {
				thisWizard!.question_pairs.forEach(pair => {
					pair.forEach((item, index) => {
						if (index === 0) {
							if (key === item) {
								setGoalStatement(data[item]);
							}
						} else {
							if (key === item) {
								setGoalDescription(data[item]);
							}

						}
					});
				});
			});

		}

	}

	const preventFormSubmit = (e: any) => {
		e.preventDefault();
	}

	const handleSubmit = () => {
		if (isGoalWizard()) {
			if (currentStep === 0) {
				// console.log(goalStatement);
				// console.log(goalDescription);
				let request = {
					strategy_id: getStrategyId(),
					statement: goalStatement,
					description: goalDescription
				}
				createGoal(request).then((resp: any) => {

				}).catch(err => {
					toast('There was an error creating your goal.');
				});
			}
		}
		// TODO: Refactor goal completion logic
		if (isGoalReview()) {
			if (currentStep === 5) {
				Object.keys(responses).forEach((key: any) => {
					if (key === 'QID5') {
						Object.keys(responses[key]).forEach((answer: any) => {
							switch (parseInt(answer)) {
								case 1:
									setModalType('archive-goal');
									setShowModal(true);
									break;
								case 2:
									setModalType('modify-goal');
									setShowModal(true);
									break;
								case 3:
									updateSurveySession();
									break;
								case 4:
									const duration = differenceInWeeks(new Date(), parseISO(props.goal.created_at));
									if (duration < 4) {
										setModalType('complete-goal');
										setShowModal(true);
									} else {
										setGoalComplete(true);
										updateSurveySession();
									}
									break;
								default:
									updateSurveySession();
									break;
							}
						});
					}

				});
				return;
			}
		}
		updateSurveySession();

	}

	const getStrategyId = () => {

		for (let i = 0; i < wizardIdArray.length; i++) {
			if (wizardIdArray[i] === props.survey.id) {
				return i + 1;
			}
		}
	}

	const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	const handleModalAction = () => {
		setShowModal(false);
		updateSurveySession();
	}

	const isGoalWizard = () => {
		if (wizardIdArray.includes(props.survey.id)) {
			return true;
		}
		return false;
	}

	const isGoalReview = () => {
		if (props.survey.id === process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID) {
			return true;
		}
		return false;
	}

	const isGoalCompletion = () => {
		if (props.survey.id === process.env.REACT_APP_GOAL_COMPLETION_SURVEY_ID) {
			return true;
		}
		return false;
	}

	const isSelfAssessment = () => {
		if (props.survey.id === process.env.REACT_APP_SELF_ASSESSMENT_SURVEY_ID) {
			return true;
		}
		return false;
	}

	const isReflection = () => {
		if (reflectionIds.includes(props.survey.id)) {
			return true;
		}
		return false;
	}

	const handleReset = () => {
		if (window.confirm('Are you sure you would like to restart this survey?')) {
			resetPage();
			startSurveySession();
		}
	}

	var resetPage = () => {
		setResponses({});
		setValidationErrors(null);
		setBtnText('Next');
		window.scrollTo(0, 0);
	}

	function getQuestions() {
		let resp: any = [];
		session.questions.map(function (item: any, index: number) {
			let message;
			let id = item.questionId;
			let qResponse;
			if (validationErrors) {
				validationErrors.forEach((err: any) => {
					//console.log(err);
					if (err.questionId === item.questionId) {
						message = err.message;
					}
				});
			}
			resp[index] = <Question key={item.questionId} sequence={index} question={item} questionResponse={handleResponse} message={message} />;
		});
		setQuestions(resp);
		setQuestionsReady(true);
		return resp;
	}

	const handleGoalStatementTextEntry = (event: any) => {
		// console.log('handleGoalStatementTextEntry');
		// console.log(event.target.value);
		setGoalStatement(event.target.value);
	}

	const handleGoalDescTextEntry = (event: any) => {
		//console.log('handleGoalDescTextEntry');
		setGoalDescription(event.target.value);
	}

	const handleModifyGoal = () => {
		setBtnText('Processing...');
		let request = {
			statement: goalStatement,
			description: goalDescription
		}
		updateGoal(props.goal.id, request).then((resp: any) => {
			toast('Changes saved successfully');
			updateSurveySession();
		}).catch((err: any) => {
			toast.error('Failed to save');
			console.log(err);
		});
	}

	const handleArchiveGoal = () => {
		setBtnText('Processing...');
		setGoalArchived(true);
		// delete workflow associated with this goal
		// update goal to archived
		let requests = [
			updateGoal(props.goal.id, { description: props.goal.description, is_archived: true }),
			resetCurrentWorkflow()
		];
		Promise.all(requests).then((resp: any) => {
			updateSurveySession();
		}).catch((err: any) => {
			toast.error('Failed to archive goal');
			console.log(err);
		});



		// updateGoal(props.goal.id, { description: props.goal.description, is_archived: true }).then((resp: any) => {
		// 	toast('Goal archived successfully');
		// 	updateSurveySession();
		// }).catch((err: any) => {
		// 	toast.error('Failed to archive goal');
		// 	console.log(err);
		// });
	}

	const handleCompleteGoal = () => {
		setBtnText('Processing...');
		updateGoal(props.goal.id, { description: props.goal.description, is_complete: true }).then((resp: any) => {
			toast('Goal marked as complete');
			updateSurveySession();
		}).catch((err: any) => {
			toast.error('Failed to mark as complete');
			console.log(err);
		});
	}

	const handleKeepGoal = () => {
		setBtnText('Processing...');
		updateSurveySession();
	}

	const handleSurveyCompletion = () => {
		let request = {};
		if (isReflection()) {

			if (props.survey.id === process.env.REACT_APP_ONBOARDING_REFLECTION_1_SURVEY_ID) {
				request = {
					workflowName: 'Onboarding',
					stepNumber: 2
				}
			} else {
				request = {
					workflowName: 'Strategy',
					reflectionId: props.survey.id
				}
			}
		}

		if (isSelfAssessment()) {
			request = {
				workflowName: 'Main'
			}
		}

		if (isGoalWizard() || isGoalReview() || isGoalCompletion()) {
			request = {
				workflowName: 'Strategy'
			}
		}

		if (isGoalCompletion()) {
			updateGoal(props.goal.id, { is_complete: true }).then((resp: any) => {
				handleStepCompletion(request);
			}).catch((err: any) => {
				toast.error('Failed to mark goal as complete');
				console.log(err);
			});
		} else {
			handleStepCompletion(request);
		}
	}




	return (
		<>
			{
				done ?
					<>
						{
							isReflection() &&
							<>
								<div className="survey-complete">
									<h2 className="mb-4">Reflection activity complete!</h2>
									<p><SunIcon className="me-2" /> You have earned 0.25 contact hours!</p>
								</div>
								<div className="form-actions">
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div className="d-flex justify-content-end">
													<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</>
						}
						{
							isGoalReview() &&
							<>
								<div className="survey-complete">
									<h2>Nice job reviewing your goal!</h2>
									<p>You are making great progress!</p>
									<div className="form-actions">
										<Container>
											<Row className="justify-content-center">
												<Col xs={12} xl={9}>
													<div className="d-flex justify-content-between">
														<Button variant="secondary" onClick={handleReset}>
															<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
																<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
																<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
															</svg>
															Restart<span className="d-none d-sm-inline"> Survey</span>
														</Button>
														<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
													</div>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
							</>
						}
						{
							isGoalCompletion() &&
							<>
								<div className="survey-complete">
									<h2>Great job completing your goal!</h2>
									<p>You are amazing!</p>
									<p><SunIcon className="me-2" /> You have earned 1 contact hour!</p>
									<div className="form-actions">
										<Container>
											<Row className="justify-content-center">
												<Col xs={12} xl={9}>
													<div className="d-flex justify-content-between">
														<Button variant="secondary" onClick={handleReset}>
															<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
																<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
																<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
															</svg>
															Restart<span className="d-none d-sm-inline"> Survey</span>
														</Button>
														<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
													</div>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
							</>
						}
						{
							isSelfAssessment() &&
							<>
								<div className="survey-complete">
									<h2>Self-assessment complete!</h2>
									<p>Great job completing this important step in your journey!</p>
									<p><SunIcon className="me-2" /> You have earned 0.25 contact hours!</p>
								</div>
								<div className="form-actions">
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div className="d-flex justify-content-end">
													<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</>
						}
						{
							isGoalWizard() &&
							<>
								<div className="survey-complete">
									<h2>Great job creating a goal!</h2>
									<p>We suggest that you work on your goal for at least 4 weeks, and complete at least 2 goal reviews.</p>
								</div>
								<div className="form-actions">
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div className="d-flex justify-content-between">
													<Button variant="secondary" onClick={handleReset}>
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
															<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
															<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
														</svg>
														Restart<span className="d-none d-sm-inline"> Survey</span>
													</Button>
													<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</>
						}
					</>
					:
					dataLoaded ?
						<div className="session-container">
							{
								isGoalReview() ?
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div id="goal-review-header">
													<h2>My Goal</h2>
													<div id="goal-metadata">
														<span><strong>Created:</strong> {format(parseISO(props.goal.created_at), 'PPP')}</span>
														<span><strong>Last reviewed:</strong> {props.goal.reviewed_at ? format(parseISO(props.goal.reviewed_at), 'PPP') : '-'}</span>
													</div>
													<div id="my-goal-container">
														<h6>Goal Statement</h6>
														<p>{goalStatement || props.goal.statement}</p>
														<h6>SMARTIE Steps</h6>
														<p>{goalDescription || props.goal.description}</p>
													</div>
												</div>
											</Col>
										</Row>
									</Container>
									:
									<></>
							}


							<Form id="session-form" onSubmit={preventFormSubmit}>
								<Container>
									<Row className="justify-content-center">
										<Col xs={12} xl={9}>
											{questions}
										</Col>
									</Row>
								</Container>
								<div className="form-actions">
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div className="d-flex justify-content-between">
													<Button variant="secondary" onClick={handleReset}>
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
															<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
															<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
														</svg>
														Restart<span className="d-none d-sm-inline"> Survey</span>
													</Button>
													<Button variant="primary" onClick={handleSubmit}>{btnText}</Button>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</Form>


							<Modal show={showModal} onHide={handleModalClose}>
								{
									modalType === 'leave-wizard' &&
									<div>
										<Modal.Header closeButton>
											<Modal.Title>Great job with this strategy!</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p className="mb-4">It appears you are using all of the recommended features for Personalized Greetings. Please consider selecting another strategy for improvement.</p>
											<Link to="/dashboard" className="btn btn-primary mb-3">Return to dashboard</Link>
											<Button variant="secondary" onClick={handleModalClose} className="d-block">Change my answers</Button>
										</Modal.Body>
									</div>
								}
								{
									modalType === 'leave-wizard-negative' &&
									<div>
										<Modal.Header closeButton>
											<Modal.Title>Not interested in working on this?</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p className="mb-4">It looks like you are not interested in working on any of the key features of this strategy. Please consider selecting another strategy for improvement.</p>
											<Link to="/dashboard" className="btn btn-primary mb-3">Return to dashboard</Link>
											<Button variant="secondary" onClick={handleModalClose} className="d-block">Change my answers</Button>
										</Modal.Body>
									</div>
								}
								{
									modalType === 'archive-goal' &&
									<div>
										<Modal.Header closeButton>
											<Modal.Title>Archive your goal</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p>Are you sure you would like to stop working on this goal? If so, you will lose all progress you have made on this goal so far.</p>
											<div className="modal-actions d-flex">
												<Button variant="secondary" className="me-2" onClick={handleModalClose}>Cancel</Button>
												<Button variant="primary" onClick={handleArchiveGoal}>{btnText === 'Processing...' ? btnText : 'Yes'}</Button>
											</div>
										</Modal.Body>
									</div>
								}
								{
									modalType === 'modify-goal' &&
									<div>
										<Modal.Header closeButton>
											<Modal.Title>Modify your goal</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<h6>Goal Statement</h6>
											<textarea value={goalStatement} onChange={handleGoalStatementTextEntry} rows={4} className="form-control mb-4"></textarea>
											<h6>SMARTIE Steps</h6>
											<textarea value={goalDescription} onChange={handleGoalDescTextEntry} rows={4} className="form-control mb-4"></textarea>
											<Button variant="primary" onClick={handleModifyGoal}>{btnText === 'Processing...' ? btnText : 'Save'}</Button>
										</Modal.Body>
									</div>
								}
								{
									modalType === 'complete-goal' &&
									<div>
										<Modal.Header closeButton>
											<Modal.Title>Complete your goal</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<p>Research suggests that we need to implement strategies for at least 4 weeks to make it a habit. Do you want to keep it for a few more weeks?</p>
											<div className="modal-actions d-flex">
												<Button variant="secondary" onClick={handleArchiveGoal}>{btnText === 'Processing...' ? btnText : 'Archive goal'}</Button>
												<Button variant="primary" onClick={handleModalClose}>{btnText === 'Processing...' ? btnText : 'Keep goal'}</Button>
											</div>
										</Modal.Body>
									</div>
								}

							</Modal>
						</div >

						:
						selfAssessmentError ?
							<>
								<div className="survey-complete">
									<h2>There was an error!</h2>
									<p>We were not able to generate your strategy scores. Don't worry, you will still get credit for your work. Please contact Maximize support at <a href="mailto:maximize@ohio.edu">maximize@ohio.edu</a>.</p>
									<p><SunIcon className="me-2" /> You have earned 0.25 contact hours!</p>
								</div>
								<div className="form-actions">
									<Container>
										<Row className="justify-content-center">
											<Col xs={12} xl={9}>
												<div className="d-flex justify-content-end">
													<Button variant="primary" onClick={handleSurveyCompletion}>Continue</Button>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</>
							:
							<div className="session">
								<Placeholder animation="glow">
									<Placeholder as="h2" className="mt-4" xs={8} />
									<Placeholder as="h3" className="mb-4" xs={12} />
									<Placeholder as="label" className="mb-4" xs={10} size="lg" />
									<Placeholder as="label" className="mb-4 d-block" xs={2} size="lg" />
									<Placeholder as="label" className="mb-4 d-block" xs={4} size="lg" />
									<Placeholder as="label" className="mb-4 d-block" xs={3} size="lg" />
									<Placeholder as="label" className="mb-4 d-block" xs={4} size="lg" />
								</Placeholder>
							</div>
			}
		</>
	)
}
import { get, put } from '../xhr';

export function getLatestAssessment() {
	let url = 'self-assessment/latest';
	return get(url);
}

export function updateAssessment(id:number, request:any) {
	let url = 'self-assessment/' + id;
	return put(url, request);
}
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import { getStrategies } from '../../_services/strategy';
import { getStrategyScores } from '../../_services/strategy-score';
import { getMyUserWorkflows } from '../../_services/user-workflow';
import { getLatestAssessment } from '../../_services/self-assessment';
import { updateAssessment } from '../../_services/self-assessment';

import StrategyCard from './strategy-card';

export default function StrategyList(props: any) {

	const [strategies, setStrategies] = useState<any>();
	const [strategyScores, setStrategyScores] = useState<any>();
	const [userWorkflows, setUserWorkflows] = useState<any>();
	const [latestAssessment, setLatestAssessment] = useState<any>();
	const [elements, setElements] = useState<any>();
	const [rating, setRating] = useState<number>();
	const [comments, setComments] = useState<string>('');
	const [showAssessmentRatingSurvey, setShowAssessmentRatingSurvey] = useState<boolean>(false);

	useEffect(() => {
		getStrategies().then((resp: any) => {
			//console.log('strategies', resp.data.data);
			setStrategies(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
		getStrategyScores().then((resp: any) => {
			//console.log('scores', resp.data.data);
			setStrategyScores(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
		getMyUserWorkflows().then((resp: any) => {
			//console.log('workflows', resp.data.data);
			setUserWorkflows(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
		getLatestAssessment().then((resp: any) => {
			console.log('latest assessment', resp.data.data);
			setLatestAssessment(resp.data.data);
			if (resp.data.data.satisfaction_rating === null) {
				setShowAssessmentRatingSurvey(true);
			}
		}).catch(err => {
			console.log(err);
		});
	}, []);

	useEffect(() => {
		if (strategies && strategyScores && userWorkflows) {
			buildElements();
		}
	}, [strategies, strategyScores, userWorkflows]);

	const buildElements = () => {
		let elements = strategies.map((strategy: any) => {
			let score = null;
			if (strategyScores.length > 0) {
				score = strategyScores[0].scores.find((score: any) => score.strategy_id === strategy.id);
			}
			let userWorkflowsForStrategy = userWorkflows.filter((workflow: any) => workflow.strategy?.strategy.id === strategy.id);
			let activeWorkflow = userWorkflowsForStrategy.find((workflow: any) => workflow.is_active);
			//console.log('workflows', userWorkflowsForStrategy);
			// Sort workflows by date and select the most recent one
			let mostRecentWorkflow = userWorkflowsForStrategy.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

			return {
				key: strategy.id,
				strategy: strategy,
				score: score,
				activeWorkflow: activeWorkflow,
				mostRecentWorkflow: mostRecentWorkflow,
				element: <StrategyCard key={strategy.id} strategy={strategy} score={score} activeWorkflow={activeWorkflow} mostRecentWorkflow={mostRecentWorkflow} />
			}
		});

		// Remove null values
		elements = elements.filter(Boolean);
		elements = elements.filter((element: any) => element !== null);

		const sortedElements = elements.sort((a: any, b: any) => {
			if (a.score && b.score) {
				return a.score.score - b.score.score
			}
		});

		// Extract the element property from each item in the elements array
		elements = sortedElements.map((item: any) => item.element);

		setElements(elements);
	}

	const handleInputChange = (event: any) => {
		setRating(event.target.value);
	}

	const handleTextInputChange = (event: any) => {
		setComments(event.target.value);
	}

	const handleSubmit = (event: any) => {
		event.preventDefault();
		let request = {
			satisfaction_rating: rating,
			comments: comments
		}
		updateAssessment(latestAssessment.id, request).then(resp => {
			setShowAssessmentRatingSurvey(false);
			toast.success('Rating saved! Thanks!');
		}).catch(err => {
			console.log(err);
		});
	}

	return (
		<div className="reflection-activities">
			<h1 className="mb-4 d-flex align-items-center justify-content-between">
				<span>Choose a Strategy</span>
				<button className="btn btn-primary" onClick={() => window.location.href = '/self-assessment'}>Self-assessment</button>
			</h1>
			<p className="mb-4">Below are the 10 Maximize strategies, categorized based on the responses on your last self-assessment activity. We recommend focusing on strategies that are labeled as Area for Growth or Developing. You can come back to this page to switch between strategies at any time.</p>
			{
				showAssessmentRatingSurvey ?
					<Card id="assessment-rating" className="mb-4" onSubmit={handleSubmit}>
						<h3>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
								<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
							</svg>
							Rate your results
						</h3>
						<Card.Body>
							<p>Thanks for completing the Self-Assessment. Please review the results below and answer this question.</p>
							<Form>
								<Form.Group className="mb-3">
									<Form.Label className="mb-2 fw-bold required">Do you agree with the results?</Form.Label>
									<div className="d-flex align-items-center">
										<Form.Check
											type="radio"
											id="rating-2"
											value="2"
											label="Yes"
											name="rating"
											onChange={handleInputChange}
										/>

										<Form.Check
											type="radio"
											id="rating-1"
											value="1"
											label="Somewhat"
											name="rating"
											onChange={handleInputChange}
										/>
										<Form.Check
											type="radio"
											id="rating-0"
											value="0"
											label="No"
											name="rating"
											onChange={handleInputChange}
										/>
									</div>

								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-2 fw-bold">Comments</Form.Label>
									<Form.Control as="textarea" rows={2} id="comments" onChange={handleTextInputChange} value={comments} />
								</Form.Group>
								<div className="d-flex align-items-center justify-content-end">
									<Button type="submit" variant="primary" className="me-0" disabled={rating === undefined}>Save</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
					:
					<></>
			}
			{elements ?
				<Row>
					{elements}
				</Row>
				:
				<Row>
					<Placeholder animation="glow">
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
						<Placeholder as="div" className="reflection-placeholder" xs={12} size="lg" />
					</Placeholder>
				</Row>
			}
		</div>
	)
}
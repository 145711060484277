import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import { createReflection, updateReflection } from '../../_services/reflection';

export default function ReflectionForm( props: any ) {
	const [ reflection, setReflection ] = useState<any>({});
	const [ isReady, setIsReady ] = useState<boolean>(false);
	const [ isProcessing, setIsProcessing ] = useState<boolean>(false);

	useEffect(() => {
		if (!isReady) {
			if (props.type === 'edit') {
				setReflection(props.reflection);
				setIsReady(true);
			} else {
				setReflection({
					name: '',
					description: '',
					survey_id: '',
					order: '',
					is_archived: false
				});
				setIsReady(true);
			}
		}
	});

	const handleTextEntry = (event: any) => {
		let data = { 
			[event.target.id]: event.target.value 
		};
		setReflection({...reflection, ...data});
	}

	const handleToggle = (event: any) => {
		let data = { 
			[event.target.id]: !reflection[event.target.id]
		};
		setReflection({...reflection, ...data});
	}

	const handleSubmit = () => {
		if ( props.type === 'edit' ) {
			updateExistingReflection();
		} else {
			createNewReflection();
		}
	}

	const updateExistingReflection = () => {
		setIsProcessing(true);
		updateReflection(props.reflection.id, reflection).then( resp => {
			toast('Reflection updated successfully');
			setIsProcessing(false);
			props.handleFormSubmit(true);
		}).catch( err => {
			console.log(err);
			toast('There was an error');
			setIsProcessing(false);
		})
	}

	const createNewReflection = () => {
		setIsProcessing(true);
		createReflection(reflection).then( resp => {
			toast('Reflection created successfully');
			setIsProcessing(false);
			props.handleFormSubmit(true);
		}).catch( err => {
			console.log(err);
			toast('There was an error');
			setIsProcessing(false);
		})
	}
	
	return (
		isReady ?
		<Form>
        	<Form.Group className="mb-3">
				<Form.Label>Name</Form.Label>
				<Form.Control type="text" id="name" value={reflection.name} onChange={handleTextEntry} required />
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Description</Form.Label>
				<Form.Control as="textarea" id="description" value={reflection.description} onChange={handleTextEntry} style={{height: '100px'}} required />
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Survey ID</Form.Label>
				<Form.Control type="text" id="survey_id" value={reflection.survey_id} onChange={handleTextEntry} required />
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Order</Form.Label>
				<Form.Control type="number" id="order" value={reflection.order} onChange={handleTextEntry} required />
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Archived</Form.Label>
				{/* <Form.Control type="number" id="order" value={reflection.order} onChange={handleTextEntry} required /> */}
				<Form.Check type="switch" id="is_archived" checked={reflection.is_archived} onChange={handleToggle} />
			</Form.Group>
			<Form.Group className="mb-3 mt-4">
		        <Button variant="primary" size="lg" onClick={handleSubmit}>Save</Button>
		    </Form.Group>
		</Form>
		:
		<div>Loading...</div>
	)
}